<template>
  <div>
    <b-loading v-model="loading"></b-loading>

    <div class="page-header">
      <h1>
        <span>{{ $tc("company", 2) }} &ndash;</span>
        {{ $t("create") }}
      </h1>
    </div>

    <div class="card">
      <div class="card-content pt-3">
        <BackButton :parent-route="{ name: 'CompanyIndex' }"></BackButton>

        <CompanyForm :mode="'create'" @submit="submit"></CompanyForm>
      </div>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from "lodash-es";
import { mapActions } from "vuex";
import BackButton from "../../components/BackButton";
import CompanyForm from "./partials/CompanyForm";

export default {
  name: "CompanyCreate",
  components: { BackButton, CompanyForm },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    ...mapActions([
      "createAddress",
      "createBillingInformation",
      "createCompany",
      "createCompanyUser",
      "createContact",
    ]),
    async submit(payload) {
      this.loading = true;

      const p = cloneDeep(payload);

      const address = await this.createAddress(p.address);
      p.address = address["@id"];

      const shippingAddress = await this.createAddress(p.shippingAddress);
      p.shippingAddress = shippingAddress["@id"];

      const billingInformationContact = await this.createContact(
        p.billingInformation.contact
      );
      p.billingInformation.contact = billingInformationContact["@id"];

      const billingInformationAddress = await this.createAddress(
        p.billingInformation.address
      );
      p.billingInformation.address = billingInformationAddress["@id"];

      const billingInformation = await this.createBillingInformation(
        p.billingInformation
      );
      p.billingInformation = billingInformation["@id"];

      const commercialContact = await this.createContact(p.commercialContact);
      p.commercialContact = commercialContact["@id"];

      const operationalContact = await this.createContact(p.operationalContact);
      p.operationalContact = operationalContact["@id"];

      this.createCompany(p)
        .then((company) => {
          this.$buefy.toast.open({
            message: this.$t("company_created"),
            type: "is-primary",
          });
          this.$router.push({
            name: "CompanyView",
            params: { id: company.id },
          });
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>
